import React from 'react'
import { Router } from "@reach/router"
import { ChatRedirect } from '../z'

export default () => (
  <Router>
    <ChatRedirect path="/__staging/z/:id" />
  </Router>
)

